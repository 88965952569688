<template>
  <v-content class="color-background">

    <router-view />

    <!-- <dashboard-core-footer /> -->
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    // components: {
    //   DashboardCoreFooter: () => import('./Footer'),
    // },
  }
</script>
<style scope>
  .color-background{
    background-color: white !important;
  }
  .editor {
    height: 30rem;
  }

  .editor .ql-container .ql-editor {
    max-height: 30rem;
  }

  .editor .ql-toolbar {
    background-color: #F5F5F5 !important;
  }
  .striped tbody tr:nth-of-type(even) {
    background-color: rgba(236, 237, 237);
  }

  .striped tbody tr:nth-of-type(odd) {
    background-color: rgb(250 ,250, 250);
  }

  .striped .v-data-footer, .striped .v-data-footer__select, .striped .v-data-footer__icons-before {
    margin-right: 0 !important;
  }
  .striped .v-select{
    margin-left: 10px !important;
  }
  .striped .v-data-footer__pagination{
    margin: 0 5px !important;
    font-weight: 700;
  }

  .label__form{
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .tabs__settings .v-tabs-bar {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 15rem;
    padding-top: 5px;
    border-right: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 0px !important;
  }

  .tabs__settings .v-tab--active i{
    color: #479ad4 !important;
  }

  .tabs__settings .v-tab--active{
    font-weight: bold;
  }

  .tabs__settings v-tab i{
    color: #CFD8DC !important;
  }

</style>
